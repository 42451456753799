/* Bulma does not have a suitable breakpoint for handsets in portrait
 * mode.  So we'll add one and use it to hide some things when using
 * mobile handsets in portrait model.
 */

@media screen and (max-width: 576px) {
  .is-hidden-handset {
    display: none !important;
  }
}

/* Bulma's modal component has a width that is locked to 640px.  We have
 * content that could use some additional space, so we're increasing the
 * size of modal components in accordance with the screen size.  Bulma's
 * container component also grows with the size of the screen, so we can
 * make our modals scale equivalently, but just a smidge smaller so that
 * the dialogs fit slightly inside of any underlying content.
 */

@media screen and (min-width: 1024px) {
  .modal-content {
    width: calc(100% - 144px);
  }
}

@media screen and (min-width: 1216px) {
  .modal-content {
    width: calc(100% - 144px);
  }
}

@media screen and (min-width: 1408px) {
  .modal-content {
    width: calc(1344px - 10em);
  }
}

/* If on mobile, make our modal dialogs fill the screen.  We're limiting
 * this to modals with the mdl-modal class; this is to allow small modals
 * such as confirmation dialog boxes to behave normally.
 */

@media screen and (max-width: 1024px) {
  /* Fill, baby, fill! */

  .mdl-modal {
    width: 100vw;
    height: 100vh;
    justify-content: normal;
  }

  .mdl-modal .modal-content {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }

  /* The box is a Bulma UI element that gives us a rounded box with a
   * white background.  On mobile, we don't want any border -- rounded
   * or otherwise.  We need the height of the box to match the content
   * container, too, since the box element has the white background and
   * we want the white background to fill the screen.
   *
   * On Safari/iOS, the browser chrome occupies the bottom of the screen
   * and will cover any content, so we need an additional 5em of padding
   * at the bottom to ensure all content is visible.
   */

  .mdl-modal .modal-content .box {
    width: 100%;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    padding-bottom: 5em;
  }

  /* Since our modals on mobile are full screen, we don't want to render
   * the close button.  Modals on mobile should provide their own burger
   * menus tailored to the modal context. */

  .mdl-modal .modal-close {
    display: none;
  }
}

/* Print styles */

@media print {
  html {
    font-size: 10pt;
    color: black;
  }
}

/* Add a popover class kinda like the modal but for non-modal controls */

.mdl-popover {
  position: fixed;
  visibility: hidden;
  z-index: 50;
}

.mdl-popover.is-active {
  visibility: visible;
}

/* Align FA icons used in titles.  Why does vertical-align: middle never
 * seem to do the right thing with text? */

.title svg.fa-svelte {
  vertical-align: -15%;
}

/* When no data is available, we want to express the situation in a
 * visible and obvious manner.  The mdl-no-data and mdl-icon-large
 * classes are used to display a large indicator icon where the data
 * would normally appear: in the center of the enclosing element.
 */

.mdl-no-data {
  text-align: center !important;
}

.mdl-no-data .mdl-icon-large {
  margin: 2em;
  width: 10em !important;
  height: 10em !important;
}

/* Generic scrolling classes since Bulma doesn't ship with any. */

.mdl-scroll {
  overflow: scroll;
}

.mdl-scroll-y {
  overflow-y: scroll;
}

.mdl-scroll-x {
  overflow-x: scroll;
}