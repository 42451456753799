/* Force the button column to be as small as possible. */
.is-button-column {
  width: 1em; }

/* Restructure time log tables on smaller widths. */
@media only screen and (max-width: 1408px) {
  table.stack {
    border-bottom: 1px solid #dbdbdb;
    /* stacked headings */ }
    table.stack thead,
    table.stack tbody,
    table.stack th,
    table.stack td,
    table.stack tr {
      display: block; }
    table.stack thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px; }
    table.stack tr {
      border: 1px solid #dbdbdb;
      border-bottom-width: 0;
      padding: 0.5em; }
      table.stack tr:last-child {
        border-bottom-width: 1; }
      table.stack tr td {
        border: none;
        border-bottom: 1px solid #dbdbdb;
        position: relative;
        margin-left: 110px;
        min-height: 40px; }
        table.stack tr td:before {
          position: absolute;
          top: 0.5em;
          left: 0.75em;
          width: auto;
          padding-right: 40px;
          white-space: nowrap;
          margin-left: -110px; }
    table.stack.table tbody tr:last-child td {
      border-bottom-width: 1px; }
    table.stack tr td:last-child,
    table.stack.table tbody tr:last-child td:last-child {
      border-bottom-width: 0; }
    table.stack.general-time-three-headings tr td:nth-of-type(1):before, table.stack.general-time-four-headings tr td:nth-of-type(1):before, table.stack.time-four-headings tr td:nth-of-type(1):before, table.stack.time-five-headings tr td:nth-of-type(1):before, table.stack.time-six-headings tr td:nth-of-type(1):before {
      content: "Name"; }
    table.stack.general-time-three-headings tr td:nth-of-type(2):before, table.stack.general-time-four-headings tr td:nth-of-type(2):before, table.stack.time-four-headings tr td:nth-of-type(2):before, table.stack.time-five-headings tr td:nth-of-type(2):before, table.stack.time-six-headings tr td:nth-of-type(2):before {
      content: "Role"; }
    table.stack.time-six-headings tr td:nth-of-type(3):before {
      content: "Job"; }
    table.stack.time-six-headings tr td:nth-of-type(4):before {
      content: "Vehicle"; }
    table.stack.time-six-headings tr td:nth-of-type(5):before {
      content: "Start"; }
    table.stack.time-six-headings tr td:nth-of-type(6):before {
      content: "Stop"; }
    table.stack.time-four-headings tr td:nth-of-type(3):before, table.stack.time-five-headings tr td:nth-of-type(3):before {
      content: "Vehicle"; }
    table.stack.time-four-headings tr td:nth-of-type(4):before, table.stack.time-five-headings tr td:nth-of-type(4):before {
      content: "Start"; }
    table.stack.general-time-three-headings tr td:nth-of-type(3):before, table.stack.general-time-four-headings tr td:nth-of-type(3):before {
      content: "Start"; }
    table.stack.general-time-four-headings tr td:nth-of-type(4):before,
    table.stack.time-five-headings tr td:nth-of-type(5):before {
      content: "Stop"; } }

@media screen and (max-width: 576px) {
  table.stack {
    /* Stack date and time fields on phone width. */ }
    table.stack .date-time-field-group {
      flex-direction: column; }
      table.stack .date-time-field-group .control:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1em; } }
